import React from "react"

const translate = str => {
  if (str === "Status") return "状态"
  if (str === "Reset test") return "重置测试状态"
  if (str === "Resetting test...") return "正在重置测试状态"
  if (str === "Missing passcode, contact BikeFinder") return "缺失秘钥，请联系Bikefinder"
  if (str === "Invalid IMEI") return "无效IMEI号"
  if (str === "Error: {x}") return "错误: {x}"
  if (str === "Loading...") return "正在加载中..."
  if (str === "Success") return "成功"
  return "沒有翻譯" // "No translation"
}
const format = (str, value) => (str + "  --  " + translate(str)).replace(/{x}/g, value)

export default class App extends React.Component {
  state = {
    imei: "",
    status: "",
    messages: [],
  }

  componentDidMount() {
    const passcode = new URLSearchParams(window.location.search).get("passcode")
    if (!passcode) {
      this.setState({ status: format("Missing passcode, contact BikeFinder") })
    }

    this.textArea.addEventListener("keydown", this.onKeyPressed)
  }

  onKeyPressed = e => {
    if (e.keyCode !== 13) return // return if not "enter" key
    if (e.preventDefault) e.preventDefault() // Prevent text field cleared
    this.textArea.select()
  }

  status = async () => {
    if (this.state.imei.length !== 15) {
      return this.setState({ status: format("Invalid IMEI") })
    }

    this.setState({ status: format("Loading..."), messages: [] })

    try {
      const passcode = new URLSearchParams(window.location.search).get("passcode")
      const url = "status?imei=" + this.state.imei + "&passcode=" + passcode
      const res = await fetch(url).then(res => res.json())
      console.log(res)
      if (!res || res.error) throw new Error(res.message)

      this.setState({
        status: res.status,
        messages: res.messages || [],
      })
    } catch (err) {
      console.log(err)
      this.setState({ status: format("Error: {x}", err.message) })
    } finally {
      this.textArea.select()
    }
  }

  reset = async () => {
    this.setState({ status: format("Resetting test..."), messages: [] })

    try {
      const passcode = new URLSearchParams(window.location.search).get("passcode")
      const url = "resetProduction?imei=" + this.state.imei + "&passcode=" + passcode
      const res = await fetch(url).then(res => res.json())
      console.log("Res: ", res)

      if (!res || res.error) throw new Error(res.message)

      this.setState({ status: format("Success") })
    } catch (err) {
      console.log(err)
      this.setState({ status: format("Error: {x}", err.message) })
    } finally {
      this.textArea.select()
    }
  }

  smt = () => (window.location.href = "/smt")

  onInput = event => {
    const imei = event.target.value.trim()
    this.setState({ imei }, () => {
      imei.length === 15 && this.status()
    })
  }

  render() {
    return (
      <div style={styles.container}>
        <textarea
          ref={textarea => (this.textArea = textarea)}
          placeholder="IMEI"
          value={this.state.imei}
          onChange={this.onInput}
          style={styles.imeiInput}
        />

        <div style={styles.buttonsContainer}>
          <button style={styles.button} onClick={this.status}>
            {format("Status")}
          </button>
          <button style={styles.button} onClick={this.reset}>
            {format("Reset test")}
          </button>
          <button style={styles.button} onClick={this.smt}>
            SMT
          </button>
        </div>

        <div style={styles.resultsContainer}>
          <p style={styles.status}>{this.state.status}</p>
          {this.state.messages.map(message => (
            <p style={styles.message} key={message}>
              {message}
            </p>
          ))}
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    flex: 1,
    flexDirection: "column",
  },

  imeiInput: {
    padding: 10,
    margin: 10,
    fontSize: 25,
  },

  buttonsContainer: {
    justifyContent: "space-around",
    margin: 10,
  },

  button: {
    flex: 1,
    height: 80,
    justifyContent: "center",
    alignItems: "center",
    fontSize: 16,
  },

  resultsContainer: {
    flexDirection: "column",
    margin: 10,
  },

  status: {
    fontWeight: "bold",
    fontSize: 28,
  },
  message: {
    fontSize: 24,
  },
}
